<template>
  <div>
    <!-- Modal Min Stock-->
    <b-modal
      id="modal-minstick"
      v-model="modalMinstock"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Minimal Stock"
    >
      <b-form>
        <div>
          <div class="messages">
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="ItemMinStock"
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Put Minimal Stock"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Submit"
                    @click="submitMinStock()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <item-add-new
      :is-add-new-item-sidebar-active.sync="isAddNewItemSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <item-edit
      :is-edit-item-sidebar-active.sync="isEditItemSidebarActive"
      :item-id.sync="ItemId"
      :item-name.sync="ItemName"
      :item-code.sync="ItemCode"
      :item-price.sync="ItemPrice"
      :item-category.sync="ItemCategory"
      :item-age-category.sync="ItemAgeCategory"
      :item-est-age.sync="ItemEstAge"
      :item-est-delivery.sync="ItemEstDelivery"
      :item-allotment.sync="ItemAllotment"
      :chosen-unit.sync="ChosenUnit"
      :chosen-currency.sync="ChosenCurrency"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="5">
            <label>Tanggal Awal</label>
            <b-form-datepicker id="start-datepicker" v-model="startDateFilter" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="5">
            <label>Tanggal Akhir</label>
            <b-form-datepicker id="end-datepicker" v-model="endDateFilter" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-center">
            <b-button variant="primary" @click="exportData()" :disabled="isLoadingExport">
              <feather-icon icon="DownloadIcon" /> Export
              <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <input
                id="fileUpload"
                name="fileUpload"
                ref="file"
                type="file"
                accept=".xlsx, .xls, .csv"
                @change="uploadFile()"
                hidden
              />
              <b-button
                variant="success"
                class="d-inline-block mr-1"
                v-if="userRole=='admin' || userRole=='super-admin'"
                @click="chooseFile()"
              >
                <span class="text-nowrap">Import</span>
              </b-button>
              &nbsp;
              <b-button
                variant="primary"
                @click="isAddNewItemSidebarActive = true"
                v-if="userRole=='admin' || userRole=='super-admin'"
              >
                <span class="text-nowrap">Add Item</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refItemListTable"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ItemName -->
        <template #cell(item_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.item_name
            }}</span>
          </div>
        </template>
        <!-- Column: ItemCategory -->
        <template #cell(item_category)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.item_category
            }}</span>
          </div>
        </template>
        <!-- Column: ItemCode -->
        <template #cell(item_code)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.item_code
            }}</span>
          </div>
        </template>

        <!-- Column: ItemCurrency -->
        <template #cell(item_currency)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.currency.currency_full_name
            }}</span>
          </div>
        </template>

        <!-- Column: ItemUnit -->
        <template #cell(item_unit)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.unit != null ? data.item.unit.unit_name : "-"
            }}</span>
          </div>
        </template>

        <!-- Column: ItemPrice -->
        <template #cell(item_price)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize"
              >{{ data.item.currency.currency_symbol }}.{{
                data.item.item_price
              }}</span
            >
          </div>
        </template>

        <!-- Column: ItemMinStock -->
        <template #cell(item_min_stock)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.item_min_stock != 0
                ? data.item.item_min_stock
                : "Need to be updated"
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="showStockHistory(data.item)"
            >
              <feather-icon icon="RefreshCcwIcon" />
              <span class="align-middle ml-50">Stock History</span>
            </b-dropdown-item>
            <b-dropdown-item @click="setMinStock(data.item.item_id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Set Min Stock</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="editItem(data.item)"
                v-if="userRole=='admin' || userRole=='super-admin'"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(data.item.item_id)" v-if="userRole=='admin' || userRole=='super-admin'">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.of"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import { saveAs } from 'file-saver'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
// import ItemesListFilters from './ItemesListFilters.vue'
import ItemList from "./ItemList"
import itemModule from "../itemModule"
import ItemAddNew from "./ItemAddNew.vue"
import ItemEdit from "./ItemEdit.vue"

const file = ""
const userRole = ""
const ItemId = ""
const ItemName = ""
const ItemCode = ""
const ItemPrice = ""
const ItemCategory = ""
const ItemAgeCategory = ""
const ItemEstAge = ""
const ItemEstDelivery = ""
const ItemAllotment = ""
const chosenUnit = ""
const ItemMinStock = ""
const chosenCurrency = ""
const modalMinstock = false

export default {
  components: {
    // ItemesListFilters,
    ItemEdit,
    ItemAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,

    vSelect,
  },
  data() {
    return {
      isLoadingExport: false,
      isItemManagement: false,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    const { role } = this.$cookies.get('userData')
    if (role.role_id === 1) {
      this.isItemManagement = true
    }
    console.log("ROLE", this.$cookies.get('userData').role)
    this.$root.$on("refreshTable", text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refItemListTable.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-item"

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
      store.registerModule(BRANCH_APP_STORE_MODULE_NAME, itemModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewItemSidebarActive = ref(false)
    const isEditItemSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItemes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemListTable,
      refetchData,

      // UI
      resolveItemRoleVariant,
      resolveItemRoleIcon,
      resolveItemStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
    } = ItemList()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ]

    return {
      modalMinstock,
      file,
      ItemId,
      ItemCode,
      ItemPrice,
      ItemCategory,
      ItemName,
      ItemMinStock,
      ItemAgeCategory,
      ItemEstAge,
      ItemEstDelivery,
      ItemAllotment,
      chosenCurrency,
      chosenUnit,
      // Sidebar
      isAddNewItemSidebarActive,
      isEditItemSidebarActive,
      userRole,
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItemes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveItemRoleVariant,
      resolveItemRoleIcon,
      resolveItemStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
    }
  },
  methods: {
    deleteItem(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_ITEMS}/${id}`
      axios
        .get(url, { headers })
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refItemListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: response.data.message,
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    chooseFile() {
      document.getElementById("fileUpload").click()
    },
    exportData() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_ITEMS}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
          },
      }).then(response => {
        console.log(response.data)
        console.log(response.headers)

        let filename = "report_item"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Import Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    getFilenameFromResponse(response) {
      // Extract the filename from the "Content-Disposition" header
      const contentDisposition = response.headers['content-disposition']
      const filenameMatch = contentDisposition.match(/filename="(.+)"/)
      if (filenameMatch && filenameMatch[1]) {
        return filenameMatch[1]
      }
      return 'downloaded_file.xlsx' // Default filename if header is not present
    },
    uploadFile() {
      const firstFile = this.$refs.file.files[0]
      this.file = firstFile
      const userToken = this.$cookies.get("userToken")
      const header = {
        "Content-Type": "multipart/form-dataitem",
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof firstFile !== "undefined") {
        formData.append("file", firstFile, firstFile.name)
        formData.append("time", moment())
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_IMPORT_ITEMS}`
        axios.post(url, formData, { headers: header }).then(response => {
          if (response.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Import Success",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Import Failed, Something went wrong on the server",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Import Failed",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      }
    },
    editItem(data) {
      const parsed = data
      console.log(parsed)
      this.ItemId = parsed.item_id
      this.ItemName = parsed.item_name
      this.ItemCode = parsed.item_code
      this.ItemPrice = parsed.item_price
      this.ItemCategory = parsed.item_category
      this.ItemAgeCategory = parsed.item_age_category
        ? {
            label: `${this.capitalizedText(parsed.item_age_category)}`,
            value: parsed.item_age_category,
          }
        : ""
      this.ItemEstAge = parsed.item_est_age ? parsed.item_est_age : ""
      this.ItemEstDelivery = parsed.item_est_delivery
        ? parsed.item_est_delivery
        : ""
      this.ItemAllotment = parsed.item_allotment
        ? {
            label: `${this.capitalizedText(parsed.item_allotment)}`,
            value: parsed.item_allotment,
          }
        : ""
      this.ChosenCurrency = {
        label: `${parsed.currency.currency_full_name} (${parsed.currency.currency_symbol})`,
        value: parsed.currency.currency_id,
      }
      this.ChosenUnit = parsed.unit !== null
          ? { label: parsed.unit.unit_name, value: parsed.unit.unit_id }
          : ""
      this.isEditItemSidebarActive = true
    },
    showStockHistory(data) {
      localStorage.setItem("ItemDetails", JSON.stringify(data))
      this.$router.push({ name: "apps-items-history-lists" })
    },
    setMinStock(id) {
      this.ItemId = id
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MIN_STOCK_ITEM}${id}`
      const userToken = this.$cookies.get("userToken")
      const header = {
        "Content-Type": "multipart/form-dataitem",
        Authorization: `Bearer ${userToken}`,
      }
      axios.get(url, { headers: header }).then(response => {
        if (response.data.success === true) {
          this.ItemMinStock = response.data.data.item_min_stock_number
          this.modalMinstock = true
        } else {
          this.ItemMinStock = 0
          this.modalMinstock = true
        }
      })
    },
    submitMinStock() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_UPDATE_MIN_STOCK_ITEM}${this.ItemId}`
      const formData = {
        item_min_stock_number: this.ItemMinStock,
        time: moment(),
      }
      axios
        .post(url, formData, { headers, crossDomain: true })
        .then(response => {
          if (response.data.success === true) {
            this.modalMinstock = false
            this.$refs.refItemListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Update Success",
                icon: "CoffeeIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    numbersFormat(value) {
      return parseFloat(value).toFixed(2)
    },
    capitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.slice(1)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
