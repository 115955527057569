<template>
  <b-sidebar
    id="add-new-branch-sidebar"
    :visible="isAddNewItemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Item
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
            ref="addNewItemForm"
            #default="{invalid}"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addNewItem"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <b-form-input
                id="Name"
                v-model="ItemName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="Category"
            rules="required"
          >
            <b-form-group
              label="Category"
              label-for="Category"
            >
              <b-form-input
                id="Category"
                v-model="ItemCategory"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item Category"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Item Code -->
          <validation-provider
            #default="validationContext"
            name="Item Code"
            rules="required"
          >
            <b-form-group
              label="Code"
              label-for="KodeItem"
            >
              <b-form-input
                id="KodeItem"
                v-model="ItemCode"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item Code"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Item Unit -->
          <validation-provider
            #default="validationContext"
            name="Unit"
            rules="required"
          >
          <b-form-group
              label="Unit"
              label-for="itemUnit"
            >
              <v-select-new
                v-model="chosenUnit"
                :filterable="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="getValidationState(validationContext)"
                :options="units"
                required="required"
                placeholder="Item Unit"
              />
            </b-form-group>
          </validation-provider>
           <!-- Item Currency -->
          <validation-provider
            #default="validationContext"
            name="Currency"
            rules="required"
          >
          <b-form-group
              label="Currency"
              label-for="itemCurrency"
            >
              <v-select-new
                v-model="chosenCurrency"
                :filterable="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="getValidationState(validationContext)"
                :options="currencies"
                required="required"
                placeholder="Item Currency"
              />
            </b-form-group>
          </validation-provider>
           <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group
              label="Price"
              label-for="Price"
            >
              <b-form-input
                id="Price"
                v-model="ItemPrice"
                :state="getValidationState(validationContext)"
                trim
                @keypress="isNumber($event)"
                placeholder="Item Price"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
             <!-- Age Category -->
            <validation-provider
              #default="validationContext"
              name="AgeCategory"
              rules="required"
            >
              <b-form-group
                label="Age Category"
                label-for="ItemAgeCategory"
              >
                <v-select-new
                  v-model="ItemAgeCategory"
                  :filterable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :state="getValidationState(validationContext)"
                  :options="itemagescategories"
                  required="required"
                  placeholder="Item Age Category"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Item Est Age -->
            <validation-provider
              #default="validationContext"
              name="ItemEstAge"
              rules="required"
            >
              <b-form-group
                label="Item Est Age"
                label-for="ItemEstAge"
              >
                <b-form-input
                  id="ItemEstAge"
                  v-model="ItemEstAge"
                  :state="getValidationState(validationContext)"
                  trim
                  @keypress="isNumber($event)"
                  placeholder="Item Est Age"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

             <!-- Item Est Delivery -->
             <validation-provider
              #default="validationContext"
              name="ItemEstDelivery"
              rules="required"
            >
              <b-form-group
                label="Item Est Delivery (Days)"
                label-for="ItemEstDelivery"
              >
                <b-form-input
                  id="ItemEstDelivery"
                  v-model="ItemEstDelivery"
                  :state="getValidationState(validationContext)"
                  trim
                  @keypress="isNumber($event)"
                  placeholder="Item Est Delivery"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
             <!-- Item Allotment -->
            <validation-provider
              #default="validationContext"
              name="ItemAllotment"
              rules="required"
            >
              <b-form-group
                label="Allotment"
                label-for="ItemAllotment"
              >
                <v-select-new
                  v-model="ItemAllotment"
                  :filterable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :state="getValidationState(validationContext)"
                  :options="allotments"
                  required="required"
                  placeholder="Item Allotment"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

             <!-- Item Image -->
            <b-form-group
              label="Image"
              label-for="file"
            >
          <b-form-file
            id="file"
            ref="file"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
              </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid && submitButtonDisabled"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useItemsList from './useItemsList'

const ItemName = ''
const ItemCode = ''
const ItemCategory = ''
const ItemPrice = '0'
const chosenUnit = ''
const units = []
const chosenCurrency = ''
const file = ''
const currencies = []
const submitButtonDisabled = false
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewItemSidebarActive',
    event: 'update:is-add-new-branch-sidebar-active',
  },
  props: {
    isAddNewItemSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitButtonDisabled,
      file,
      chosenUnit,
      units,
      chosenCurrency,
      currencies,
      required,
      alphaNum,
      roles: [],
      branches: [],
      ItemCode,
      ItemPrice,
      ItemName,
      ItemCategory,
      ItemAgeCategory: { label: `Medium`, value: 'medium' },
      ItemEstAge: "0",
      ItemEstDelivery: "0",
      ItemAllotment: { label: `Production`, value: 'production' },
      itemagescategories: [{ label: `Slow`, value: 'slow' }, { label: `Medium`, value: 'medium' }, { label: `Fast`, value: 'fast' }],
      allotments: [{ label: `Production`, value: 'production' }, { label: `Non Production`, value: 'nonproduction' }],
    }
  },
  setup() {
    const blankItemData = {
      Name: '',
      branchname: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const genders = [
          { label: 'Male', value: 'L' },
          { label: 'Female', value: 'P' },
        ]
    const branchData = ref(blankItemData)
    const resetbranchData = () => {
      branchData.value = blankItemData
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetbranchData)

    return {
      branchData,
      refFormObserver,
      getValidationState,
      resetForm,
      genders,
    }
  },
  mounted() {
      this.getUnits()
      this.getCurrencies()
  },
  methods: {
    isNumber: evt => {
      evt = (evt) ? evt : window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
      getCurrencies() {
        const token = this.$cookies.get('userToken')
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CURRENCIES}`, { headers })
              .then(response => {
                const itemJoin = response.data.data.map(elem => (
                  { label: `${elem.currency_full_name} (${elem.currency_symbol})`, value: elem.currency_id }
                ))
                this.currencies = itemJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getUnits() {
        const token = this.$cookies.get('userToken')
const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
}
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_UNIT}`, { headers })
              .then(response => {
                const itemJoin = response.data.data.map(elem => (
                  { label: elem.unit_name, value: elem.unit_id }
                ))
                this.units = itemJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    addNewItem() {
      this.submitButtonDisabled = true
      const token = this.$cookies.get('userToken')
const headers = {
  'Access-Control-Allow-Origin': '*',
  "Content-Type": "multipart/form-dataitem",
  Authorization: `Bearer ${token}`,
}
      this.$refs.addNewItemForm.validate().then(success => {
        const formData = new FormData()
        if (typeof this.file[0] !== "undefined") {
          formData.append("file", this.file[0], this.file[0].name)
        }
        formData.append("item_name", this.ItemName)
        formData.append("item_category", this.ItemCategory)
        formData.append("item_code", this.ItemCode)
        formData.append("item_price", this.ItemPrice)
        formData.append("item_unit_id", this.chosenUnit.value)
        formData.append("item_currency_id", this.chosenCurrency.value)
        formData.append("item_age_category", this.ItemAgeCategory.value)
        formData.append("item_est_age", this.ItemEstAge)
        formData.append("item_est_delivery", this.ItemEstDelivery)
        formData.append("item_allotment", this.ItemAllotment.value)
        formData.append("time", moment())
        if (success) {
          const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_ITEMS}`
          axios
          .post(url, formData, { headers, crossDomain: true })
          .then(response => {
            this.submitButtonDisabled = false
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.success === true) {
                this.ItemName = ""
                this.ItemCode = ""
                this.ItemPrice = ""
                this.chosenUnit = ""
                this.chosenCurrency = ""
                this.isAddNewItemSidebarActive = false
                this.$root.$emit('refreshTable', 'refreshTable')
                // this.$parent.refItemListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-branch-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
