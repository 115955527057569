<template>
  <b-sidebar
    id="edit-branch-sidebar"
    :visible="isEditItemSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-item-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Edit Item
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="editItemForm" #default="{invalid}">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="editItem"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group label="Name" label-for="Name">
              <b-form-input
                id="Name"
                v-model="ItemName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Item Code -->
          <validation-provider
            #default="validationContext"
            name="Item Code"
          >
            <b-form-group label="Code" label-for="KodeItem">
              <b-form-input
                id="KodeItem"
                v-model="ItemCode"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item Code"
                readonly = "readonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Item Unit -->
          <validation-provider
            #default="validationContext"
            name="Unit"
          >
            <b-form-group label="Unit" label-for="itemUnit">
              <v-select-new
                v-model="ChosenUnit"
                :filterable="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="getValidationState(validationContext)"
                :options="units"
                placeholder="Item Unit"
              />
            </b-form-group>
          </validation-provider>
          <!-- Item Currency -->
          <validation-provider
            #default="validationContext"
            name="Currency"
          >
            <b-form-group label="Currency" label-for="itemCurrency">
              <v-select-new
                v-model="ChosenCurrency"
                :filterable="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="getValidationState(validationContext)"
                :options="currencies"
                :readonly="true"
                placeholder="Item Currency"
                disabled
              />
            </b-form-group>
          </validation-provider>
          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
          >
            <b-form-group label="Price" label-for="Price">
              <b-form-input
                id="Price"
                v-model="ItemPrice"
                :state="getValidationState(validationContext)"
                trim
                @keypress="isNumber($event)"
                placeholder="Item Price"
                readonly = "readonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Category"
          >
            <b-form-group label="Category" label-for="Category">
              <b-form-input
                id="Category"
                v-model="ItemCategory"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Item Category"
                readonly = "readonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Age Category -->
          <validation-provider
            #default="validationContext"
            name="AgeCategory"
          >
            <b-form-group label="Age Category" label-for="ItemAgeCategory">
              <v-select-new
                v-model="ItemAgeCategory"
                :filterable="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="getValidationState(validationContext)"
                :options="itemagescategories"
                placeholder="Item Age Category"
                readonly = "readonly"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Item Est Age -->
          <validation-provider
            #default="validationContext"
            name="ItemEstAge"
          >
            <b-form-group label="Item Est Age" label-for="ItemEstAge">
              <b-form-input
                id="ItemEstAge"
                v-model="ItemEstAge"
                :state="getValidationState(validationContext)"
                trim
                @keypress="isNumber($event)"
                placeholder="Item Est Age"
                readonly = "readonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Item Est Delivery -->
          <validation-provider
            #default="validationContext"
            name="ItemEstDelivery"
          >
            <b-form-group
              label="Item Est Delivery (Days)"
              label-for="ItemEstDelivery"
            >
              <b-form-input
                id="ItemEstDelivery"
                v-model="ItemEstDelivery"
                :state="getValidationState(validationContext)"
                trim
                @keypress="isNumber($event)"
                placeholder="Item Est Delivery"
                readonly = "readonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Item Allotment -->
          <validation-provider
            #default="validationContext"
            name="ItemAllotment"
          >
            <b-form-group label="Allotment" label-for="ItemAllotment">
              <v-select-new
                v-model="ItemAllotment"
                :filterable="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="getValidationState(validationContext)"
                :options="allotments"
                placeholder="Item Allotment"
                :readonly="true"
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
              :disabled="invalid"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, alphaNum } from "@validations"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import axios from "axios"
import { ref } from "@vue/composition-api"
import moment from "moment"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
// import useItemsList from './useItemsList'

const units = []
const currencies = []
const file = ""
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditItemSidebarActive",
    event: "update:is-edit-branch-sidebar-active",
  },
  props: {
    isEditItemSidebarActive: {
      type: Boolean,
      required: true,
    },
    ItemId: {
      type: Number,
      required: true,
    },
    ItemName: {
      type: String,
      required: true,
    },
    ItemCode: {
      type: String,
      required: true,
    },
    ItemPrice: {
      type: String,
      required: true,
    },
    ItemCategory: {
      type: String,
      required: true,
    },
    ItemAgeCategory: {
      type: String,
      required: true,
    },
    ItemEstAge: {
      type: String,
      required: true,
    },
    ItemEstDelivery: {
      type: String,
      required: true,
    },
    ItemAllotment: {
      type: String,
      required: true,
    },
    ChosenCurrency: {
      type: Object,
      required: true,
    },
    ChosenUnit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      itemagescategories: [
        { label: `Slow`, value: "slow" },
        { label: `Medium`, value: "medium" },
        { label: `Fast`, value: "fast" },
      ],
      allotments: [
        { label: `Production`, value: "production" },
        { label: `Non Production`, value: "nonproduction" },
      ],
      units,
      currencies,
      required,
      alphaNum,
      roles: [],
      branches: [],
      file,
    }
  },
  setup() {
    const blankItemData = {
      Name: "",
      branchname: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    }

    const genders = [
      { label: "Male", value: "L" },
      { label: "Female", value: "P" },
    ]
    const branchData = ref(blankItemData)
    const resetbranchData = () => {
      branchData.value = blankItemData
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetbranchData,
    )

    return {
      branchData,
      refFormObserver,
      getValidationState,
      resetForm,
      genders,
    }
  },
  mounted() {
    this.getUnits()
    this.getCurrencies()
  },
  methods: {
    isNumber: evt => {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getCurrencies() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CURRENCIES}`,
          { headers },
        )
        .then(response => {
          const itemJoin = response.data.data.map(elem => ({
            label: `${elem.currency_full_name} (${elem.currency_symbol})`,
            value: elem.currency_id,
          }))
          this.currencies = itemJoin
        })
        .catch(err => {
          console.log(err)
        })
    },
    getUnits() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_UNIT}`,
          { headers },
        )
        .then(response => {
          const itemJoin = response.data.data.map(elem => ({
            label: elem.unit_name,
            value: elem.unit_id,
          }))
          this.units = itemJoin
        })
        .catch(err => {
          console.log(err)
        })
    },
    editItem() {
      const userToken = this.$cookies.get("userToken")
      this.$refs.editItemForm.validate().then(success => {
        const formData = new FormData()
        if (typeof this.file[0] !== "undefined") {
          formData.append("file", this.file[0], this.file[0].name)
        }
        formData.append("item_id", this.ItemId)
        formData.append("item_name", this.ItemName)
        formData.append("item_code", this.ItemCode)
        formData.append("item_price", this.ItemPrice)
        formData.append("item_category", this.ItemCategory)
        formData.append("item_age_category", this.ItemAgeCategory.value)
        formData.append("item_est_age", this.ItemEstAge)
        formData.append("item_est_delivery", this.ItemEstDelivery)
        formData.append("item_allotment", this.ItemAllotment.value)
        formData.append("item_unit_id", this.ChosenUnit.value)
        formData.append("item_currency_id", this.ChosenCurrency.value)
        formData.append("time", moment())
        const header = {
          "Content-Type": "multipart/form-dataitem",
          Authorization: `Bearer ${userToken}`,
        }
        if (success) {
          const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_ITEMS}`
          console.log(url)
          axios
            .post(url, formData, { headers: header, crossDomain: true })
            .then(response => {
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              if (response.data.success === true) {
                this.ItemName = ""
                this.ItemCode = ""
                this.ItemPrice = ""
                this.ItemCategory = ""
                this.chosenUnit = ""
                this.chosenCurrency = ""
                this.isEditItemSidebarActive = false
                this.$root.$emit("refreshTable", "refreshTable")
                // this.$parent.refItemListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Congratulation",
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: "Your data has been inserted!",
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Notification",
                    icon: "BellIcon",
                    variant: "danger",
                    text: "Something went wrong",
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Notification",
                  icon: "BellIcon",
                  variant: "danger",
                  text: "Something went wrong",
                },
              })
            })
        }
      })
    },
    handleFilesUpload(e) {
      this.file = e.target.files || e.dataTransfer.files
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-branch-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important
  }
}
</style>
